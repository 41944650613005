<template>
  <keep-alive include="" :exclude="[]">
    <router-view></router-view>
  </keep-alive>
</template>

<script>
export default {};
</script>

<style></style>